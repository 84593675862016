*, body {
  box-sizing: border-box;
  font-family: 'Space Grotesk', 'Playfair Display', serif;
}

.header {
  font-size: 32px;
  padding: 50px 0;
  text-align: center;
  font-family: 'Space Grotesk', 'Playfair Display', serif;
}

.confetti-button {
  outline: none;
  background: none;
  border: none;
  font-size: 32px;
  font-family: 'Space Grotesk', 'Playfair Display', serif;
  cursor: pointer;
}

.separator {
  border-top: 1px solid black;
  width: 250px;
  margin: auto;
}

.display {
  font-weight: bold;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message-content {
  background-color: white;
  font-size: 32px;
}

.parallax-bg {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.paint-image {
  background-image: url(./lego.jpg);
  height: 80vh;
  margin-top: 50px;
}

.interlude {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  font-size: 21px;
  min-height: 300px;
}

.tree-image {
  background-image: url(./space.jpg);
  height: 100vh;
}

.faq {
  background-color: white;
  margin: auto;
  max-width: 700px;
  padding: 30px;
}

.question {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  background: none;
  border: none;
  border-bottom: 1px solid black;
  cursor: pointer;
  padding: 15px 0;
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
}

.answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.answer .text {
  padding: 15px 0;
  text-align: left;
  line-height: 1.3em;
}

.sponsors {
  margin-top: 50px;
}

.logos {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 2s all ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logos.active {
  transform: translateY(0);
  opacity: 1;
}

.logo {
  max-width: 90%;
  margin-bottom: 10px;
}

@media screen and (min-width:600px) {
  .header {
    font-size: 48px;
  }

  .confetti-button {
    font-size: 48px;
  }

  .logos {
    flex-direction: row;
  }

  .logo {
    max-width: 18%;
    margin-bottom: 0;
  }
}

.google-wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
